const retrieveFormattedCategories = (categorySlugs, props) => {
  /**
    * retrieve the "pretty" name for each category slug from Algolia's searchResults
    * because we cannot determine if a slug has special characters
    * i.e) whats-new -> What's New,   shoes-booties -> Shoes & Booties
    */

  if (!categorySlugs.length) return [];
  const hierarchicalMenu = props?.searchState?.hierarchicalMenu?.['categories_slug.lvl0'] ?? null;

  const searchResults = props.resultsState?.rawResults?.[0] ?? props.searchResults;

  const level = categorySlugs.length - 1;

  const categoriesSlugArray = searchResults?.hits?.[0]?.categories_slug?.[`lvl${level}`] ?? [];
  const correctHierMenuIndex = categoriesSlugArray.indexOf(hierarchicalMenu);

  if (correctHierMenuIndex === -1) return [];

  const prettyCategoriesArray = searchResults?.hits?.[0]?.categories?.[`lvl${level}`] ?? [];
  const correctHierarchicalMenu = prettyCategoriesArray[correctHierMenuIndex] ?? [];

  return correctHierarchicalMenu?.split?.(' > ') ?? [];
};

export default retrieveFormattedCategories;
