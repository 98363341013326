// note: this method cannot differientate special characters
// ex) 'whats-new' -> returns 'Whats New' rather than 'What's New'
const prettifySlug = (slug) => {
  if (slug === 'whats-new') {
    /* This is a specific edge case for the top level HM of 'whats-new'.
    * our SEO metadata template does not have any specified rules
    * for either dynamic or static overrides for this HM. As a temp fix, we will just return out
    * the correct punctuation.
    *
    * please remove this conditional once we add in a dynamic template
    * or static override for the HM 'whats-new'
    */
    return 'What\'s New';
  }

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.substring(1);

  return slug.split('-').reduce((acc, curr) => {
    if (acc.length === 0) return capitalizeFirstLetter(curr);
    return `${acc} ${capitalizeFirstLetter(curr)}`;
  }, '');
};

export default prettifySlug;
